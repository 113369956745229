<template>
    <div>
       <!-- {{ userData }}  -->
       <div class="d-flex justify-content-center mb-1" variant="primary" v-if="isLoading">
      <strong class="text-center ">Yükleniyor...</strong>
      <b-spinner class="m-auto" variant="primary" />
    </div>
      <b-row v-if="!isLoading">
    <b-col
      md="2"
      sm="4"
      class="my-1"
    >
      <b-form-group
        class="mb-0"
      >
        <label class="d-inline-block text-sm-left mr-50">Liste adeti</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
     <b-col
      md="4"
      sm="8"
      class="my-1"
    >
    <!--  <b-form-group
        label="Sort"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="sortBySelect"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            v-model="sortBy"
            :options="sortOptions"
            class="w-75"
          >
            <template v-slot:first>
              <option value="">
                -- none --
              </option>
            </template>
          </b-form-select>
          <b-form-select
            v-model="sortDesc"
            size="sm"
            :disabled="!sortBy"
            class="w-25"
          >
            <option :value="false">
              Asc
            </option>
            <option :value="true">
              Desc
            </option>
          </b-form-select>
        </b-input-group>
      </b-form-group>-->
    </b-col> 
    <b-col
      md="6"
     
      class="my-1"
    >
      <b-form-group
        label="Ara"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Kullanıcı adı.."
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Temizle
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        v-if="!isLoading && items.length > 0"
        @filtered="onFiltered"
        @row-clicked="rowClicked"
      >
      <!-- <template #cell(id)="data" >
         
         {{ data.item.user.userId}}
       </template> -->
        <!-- <template #cell(avatar)="data">
          <b-avatar :src="userImgBaseUrl+data.image" />
        </template> -->
        <template #cell(name)="data">
            {{ data.item.departmentUser.user.name +' - '+ data.item.departmentUser.user.surname }} 
        </template>
        <template #cell(Department)="data">
          {{ data.item.project.department.name }}
        </template>
        <template #cell(Project)="data">
          {{ data.item.project.name}}
        </template>
        <template #cell(worktime)="data">
          <!-- {{ data }} -->
          <p style="color: #7367F0;font-weight: bold;margin-top:15px">      {{  saniyeyiGuneCevir(data.item.projectDepartmentUserWorkTime).saat+'s:'+saniyeyiGuneCevir(data.item.projectDepartmentUserWorkTime).dakika+'dk:'+saniyeyiGuneCevir(data.item.projectDepartmentUserWorkTime).saniye+'sn'}}</p>
        </template>
      
        <template #cell(Productivity)="data">
          
          <b-progress
         
          :value=" data.item.projectDepartmentUserDayBasedProductivity.efficientLast365Day.efficientPercentage"

            max="100"
            variant="data.value.variant"
            striped
          />
          {{ data.item.projectDepartmentUserDayBasedProductivity.efficientLast365Day.efficientPercentage + '%' }}
        </template>
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="data.item.isOnline ? 'success' : 'danger'"
          >
            {{ data.item.isOnline? 'Aktif' : 'Kapalı' }}
          </b-badge>
        </template>
        
      </b-table>
      <h5 v-else class="text-center py-5">Kayıtlı Proje Bulunamadı..</h5>
    </b-col>

    <b-col
      cols="12"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
      <!-- <b-table
        small
        :fields="fields"
        :items="items"
        responsive="sm"
        striped
        v-if="!isLoading"
        @row-clicked="rowClicked"
      >
     
      
        <template #cell(index)="data" >
         
          {{ data.item.departmentUser.id}}
        </template>
        <template #cell(name)="data">
            {{ data.item.user.name +' - '+ data.item.user.lastname }} 
        </template>
        <template #cell(Department)="data">
          {{ data.item.departmentUser.department.name }}
        </template>
        <template #cell(Project)="data">
          {{ data.item.project.name}}
        </template>
        <template #cell(Productivity)="">
          
          <b-progress
         
            :value="80"
            max="100"
            variant="data.value.variant"
            striped
          />
          {{ 20 + '%' }}
        </template>
      
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="data.item.user.image !== '/Images/defaultUser.jpg' ? 'success' : 'danger'"
          >
            {{ data.item.user.image !== '/Images/defaultUser.jpg'? 'Aktif' : 'Kapalı' }}
          </b-badge>
        </template>
  
        <template #cell(price)="data">
          {{ '$'+data.value }}
        </template>
  
        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table> -->
    </div>
  </template>
  
  <script>
  import { BTable, BProgress, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,BSpinner } from 'bootstrap-vue'
  import { Api } from '@/helpers/Api/apiConfig'
  import { BASE_URL } from '@/helpers/Url/BaseUrl'
  export default {
    components: {
      BTable,
      BProgress,
      BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,BSpinner
    },
    data() {
      return {
        isLoading:false,
        selectedDepartment: 28,
        projectId:null,
        userData:null,
        userImgBaseUrl:BASE_URL,
        perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
        fields: [
          // A virtual column that doesn't exist in items
          // { key: 'id', label: 'Id', sortable: true  },

          // A column that needs custom formatting
          { key: 'name', label: 'Adı', sortable: true },
           { key: 'Department', label: 'Departman', sortable: true  },
           { key: 'Project', label: 'Proje' },

          
        //   // A regular column
           { key: 'Productivity', label: 'Verimlilik' },
        //   // A regular column
        {key:'worktime',label:'Geçen süre'},
          { key: 'status', label: 'Durum' },
        //  // A virtual column made up from two fields
        //   { key: 'price', label: 'Aktif Ekran' },
        ],
        items: [
          {
            name: { first: 'Fitbit', last: 'Activity Tracker' },
            Category: 'Fitness',
            order_status: { status: 'Delivered', variant: 'success' },
            Popularity: { value: 50, variant: 'success' },
            price: 99.99,
          },
          {
            name: { first: 'Fitbit ', last: 'Flex Wireless Activity' },
            Category: 'Fitness',
            order_status: { status: 'Pending', variant: 'primary' },
            Popularity: { value: 36, variant: 'primary' },
            price: 89.85,
          },
          {
            name: { first: 'Fitbit', last: 'Sleep Tracker Wristband' },
            Category: 'Fitness',
            order_status: { status: 'Delivered', variant: 'success' },
            Popularity: { value: 76, variant: 'success' },
            price: 65.25,
          },
          {
            name: { first: 'Fitbit', last: 'Sleep Wristband' },
            Category: 'Fitness',
            order_status: { status: 'On Hold', variant: 'warning' },
            Popularity: { value: 55, variant: 'warning' },
            price: 75.55,
          },
          {
            name: { first: 'Fitbit', last: 'Clip for Zip Wireless Activity Trackers' },
            Category: 'Fitness',
            order_status: { status: 'Canceled', variant: 'danger' },
            Popularity: { value: 75, variant: 'danger' },
            price: 105.55,
          },
        ],
      }
    },
    computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
    watch: {
      "$route.params.id": {
      handler: function(value) {
       //alert(value);
      
       value !=='undefined'&&this.$route.params.id? this.projectId=value:this.projectId=''
        console.log('prjectıd',this.projectId);
        this.getDepartmentProjects( this.projectId)
      },
      deep: true,
      immediate: true,
    },

    
 
  items(val){
    this.items=val
  }
    
    },
    mounted() {
      this.getDepartmentProjects(this.projectId)
    },
    methods: {
    saniyeyiGuneCevir(saniye) {
  const gun = Math.floor(saniye / 86400); // 86400 saniye bir güne eşittir.
  saniye %= 86400; // Kalan saniyeyi alıyoruz.
  const saat = Math.floor(saniye / 3600); // 3600 saniye bir saate eşittir.
  saniye %= 3600; // Kalan saniyeyi alıyoruz.
  const dakika = Math.floor(saniye / 60); // 60 saniye bir dakikaya eşittir.
  saniye %= 60; // Kalan saniyeyi alıyoruz.

  return {
    gun,
    saat,
    dakika,
    saniye,
  }
},



// console.log(
//   `Verilen ${saniyeDegeri} saniye, ${sonuc.gun} gün, ${sonuc.saat} saat, ${sonuc.dakika} dakika ve ${sonuc.saniye} saniyeye eşittir.`
// )
      rowClicked(item, index, event){
        this.userData=item.user.userId

        console.log('event',item,index,event);
        this.$router.push({name:'profile',params:{user_id:item.user.userId}})
        
      },
      onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
       async getDepartmentProjects(projectId){
        this.isLoading=true
         //  await Api('GET','Projects/Detail?departmentId='+departmentId)
         await Api('GET','ProjectDepartmentUser?projectId='+projectId)
            .then(response => {
                console.log('deparmentProjectsUser',response.data.data,projectId);
                this.items = response.data.data.items.map(item => {
    return {
        ...item,
        user:item.departmentUser.user
    };
});
this.totalRows = this.items.length
// console.log('itemsss',this.items[0].user);
            })
            .catch(error => {
                console.log(error)
            })
            this.isLoading=false
        }
    }
  }
  </script>