<template>
  <div>
    <deparments v-if="isDepartments" />
    <projects v-else />

    <b-tabs content-class="pt-1" :value="isDepartments ? 0 : 1" fill>
      <b-tab title="Departmanlar" @click="CLICKED_TAB(true)">
        <departmentsTable />
      </b-tab>
      <b-tab title="Projeler" @click="CLICKED_TAB(false)">
        <projectsTable />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BCardText } from "bootstrap-vue";

import departmentsTable from "@/views/pages/productivity/departments/departmentsTable.vue";
import projectsTable from "@/views/pages/productivity/projects/projectsTable.vue";
import deparments from "@/views/pages/productivity/departments/departments.vue";
import projects from "@/views/pages/productivity/projects/projects.vue";
import { methods } from "vue-echarts";

export default {
  components: {
    BTabs,
    BTab,
    BCardText,
    departmentsTable,
    projectsTable,
    deparments,
    projects,
  },
  data() {
    return {
      isDepartments: true,
    };
  },
  methods: {
    CLICKED_TAB(isDepartments) {
      this.isDepartments = isDepartments;
      this.$router.replace({ query: { tab: isDepartments ? "departments" : "projects" } });
    },
    GET_QUERY() {
      const query = this.$route.query;
      console.log(query, "quetry");
      if (query.tab) {
        this.isDepartments = query.tab === "departments";
      }
    },
  },
  created() {
    this.GET_QUERY();
  },
};
</script>
