<template>
    <div>
      <div class="d-flex justify-content-center mb-1" variant="primary" v-if="isLoading">
      <strong>Yükleniyor...</strong>
      <b-spinner class="m-auto" variant="primary" />
    </div>
      <b-row v-if="!isLoading">
    <b-col
      md="2"
      sm="4"
      class="my-1"
    >
      <b-form-group
        class="mb-0"
      >
        <label class="d-inline-block text-sm-left mr-50">Liste adeti</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col
      md="4"
      sm="8"
      class="my-1"
    >
      <!-- <b-form-group
        label="Sırala"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="sortBySelect"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            v-model="sortBy"
            :options="sortOptions"
            class="w-75"
          >
            <template v-slot:first>
              <option value="">
                -- none --
              </option>
            </template>
          </b-form-select>
          <b-form-select
            v-model="sortDesc"
            size="sm"
            :disabled="!sortBy"
            class="w-25"
          >
            <option :value="false">
              Asc
            </option>
            <option :value="true">
              Desc
            </option>
          </b-form-select>
        </b-input-group>
      </b-form-group> -->
    </b-col>
    <b-col
      md="6"
      class="my-1"
    >
      <b-form-group
        label="Ara"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Ad ile arama yapın..."
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Temizle
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-table
      striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        v-if="!isLoading"
        @filtered="onFiltered"
        @row-clicked="rowClicked"
      >
        <!-- A virtual column -->
        <!-- <template #cell(id)="data">
          {{ data.item.user.userId}}
        </template> -->
  
        <!-- A custom formatted column -->
        <template #cell(name)="data">
          <!-- {{ data.item.user }} -->
          {{ data.item.user.name +'  '+ data.item.user.surname  }}
        </template>
        <template #cell(Department)="data">
          <!-- {{ data.item.user }} -->
          {{ data.item.department.name  }}
        </template>
        <template #cell(departmentRole)="data">
          <!-- {{ data.item.user }} -->
          {{ data.item.user.generalRole == 'Admin' ?'Admin':data.item.departmentRole == 'DepartmentManager' ?'Deparmant yöneticisi':'Çalışan' }}

        </template>
        <!-- A custom formatted column -->
        <template #cell(Productivity)="data">
            <!-- data.item.efficiencyInformation.efficient -->
          <b-progress
         
            :value=" data.item.departmentUserDayBasedProductivity.efficientLast365Day.efficientPercentage"
            max="100"
            variant="data.value.variant"
            striped
          />
          {{ data.item.departmentUserDayBasedProductivity.efficientLast365Day.efficientPercentage + '%' }}
          <!-- {{ 80 + '%' }} -->
        </template>
      
        <template #cell(status)="data">
          <b-badge
          pill
            :variant="data.item.isOnline ? 'success' : 'danger'"
          >
            {{ data.item.isOnline? 'Aktif' : 'Kapalı' }}
          </b-badge>
        </template>
<!--   
        <template #cell(price)="data">
          {{ '$'+data.value }}
        </template>
  
        <template #cell()="data">
          {{ data.value }}
        </template> -->
      </b-table>
    </b-col>
      <b-col
      cols="12"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
    </div>
  </template>
  
  <script>
  import { BTable, BProgress, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,BSpinner } from 'bootstrap-vue'
  import { Api } from '@/helpers/Api/apiConfig'
  import { BASE_URL } from '@/helpers/Url/BaseUrl'
  export default {
    components: {
      BTable,
      BProgress,
      BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,BSpinner
    },
    data() {
      return {
        selectedDepartment: 28,
        isLoading:false,
        departmentId:null,
        userData:null,
        userImgBaseUrl:BASE_URL,
        perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
        fields: [
          // A virtual column that doesn't exist in items
         
          // { key: 'id', label: 'Id', sortable: true  },

          // A column that needs custom formatting
          { key: 'name', label: 'Adı', sortable: true  },
          { key: 'Department', label: 'Departman', sortable: true  },
          { key: 'departmentRole', label: 'Departmandaki Rolü', sortable: true  },
          
          // A regular column
          { key: 'Productivity', label: 'Verimlilik', sortable: true  },
          // A regular column
          { key: 'status', label: 'Durum', sortable: true  },
          // A virtual column made up from two fields
        //   { key: 'price', label: 'Aktif Ekran' },
        ],
        items: [
          {
            name: { first: 'Fitbit', last: 'Activity Tracker' },
            Category: 'Fitness',
            order_status: { status: 'Delivered', variant: 'success' },
            Popularity: { value: 50, variant: 'success' },
            price: 99.99,
          },
          {
            name: { first: 'Fitbit ', last: 'Flex Wireless Activity' },
            Category: 'Fitness',
            order_status: { status: 'Pending', variant: 'primary' },
            Popularity: { value: 36, variant: 'primary' },
            price: 89.85,
          },
          {
            name: { first: 'Fitbit', last: 'Sleep Tracker Wristband' },
            Category: 'Fitness',
            order_status: { status: 'Delivered', variant: 'success' },
            Popularity: { value: 76, variant: 'success' },
            price: 65.25,
          },
          {
            name: { first: 'Fitbit', last: 'Sleep Wristband' },
            Category: 'Fitness',
            order_status: { status: 'On Hold', variant: 'warning' },
            Popularity: { value: 55, variant: 'warning' },
            price: 75.55,
          },
          {
            name: { first: 'Fitbit', last: 'Clip for Zip Wireless Activity Trackers' },
            Category: 'Fitness',
            order_status: { status: 'Canceled', variant: 'danger' },
            Popularity: { value: 75, variant: 'danger' },
            price: 105.55,
          },
        ],
      }
    },
    computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
    watch: {
      "$route.params.id": {
      handler: function(value) {
      //  alert(value);
      
        this.departmentId=value
        console.log('deparmentId',this.departmentId);
        this.getDepartmentUsers( this.departmentId)
      },
      deep: true,
      immediate: true,
    },

    
 
  items(val){
    this.items=val
  }
    
    },
    mounted() {
      this.getDepartmentUsers(this.departmentId)
    },
    methods: {
      rowClicked(item){
        this.userData=item.user.userId

        console.log('event',item);
        this.$router.push({name:'profile',params:{user_id:item.user.userId}})
        
      },
      onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
       async getDepartmentUsers(departmentId){
        this.isLoading=true

           await Api('GET','DepartmentUser?departmentId='+(departmentId?departmentId:''))
            .then(response => {
                console.log('deparmentUsers',response.data.data);
                this.items = response.data.data.items.map(item => {
    return {
        ...item,
        user:item.user
    };
});
this.totalRows = this.items.length
            })
            .catch(error => {
                console.log(error)
            })
            this.isLoading=false
        }
    }
  }
  </script>