<template>
  <div>
    <div v-if="isLoading">loading</div>
    <div v-else>
      <!-- {{ departmentId }} -->
      <VueSlickCarousel v-bind="settings">
        <div class="card earnings-card text-center" v-for="(card, index) in departments" :key="index" :class="{ active: card.value === departmentId }" @click="toggleActive(card)">
          <!----><!---->
          <div class="card-body">
            <!----><!---->
            <div class="row justify-content-center">
              <div class="col-5 departmentCard" >
                <VueApexCharts type="radialBar" :options="chartOptions" :series="[departments[index].productivity]" class="mt-1"></VueApexCharts>
              </div>
              <div class="col-12">
                <h4 class="card-title mb-1">
                  <span
                    ><b>{{ card.text }} </b></span
                  >
                </h4>
              </div>
              <!-- <div class="col-6">
                <VueApexCharts type="radialBar" :options="chartOptions" :series="series" class="mt-1"></VueApexCharts>
              </div> -->
            </div>
          </div>
          <!----><!---->
        </div>
      </VueSlickCarousel>
    </div>
    <!-- <VueApexCharts type="radialBar" :options="chartOptions" :series="series"></VueApexCharts> -->
  </div>
</template>

<script>
import { Api } from "@/helpers/Api/apiConfig.js";

import VueApexCharts from "vue-apexcharts";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
const $earningsStrokeColor2 = "#28c76f66";
const $earningsStrokeColor3 = "#28c76f33";
export default {
  components: {
    VueSlickCarousel,
    VueApexCharts,
  },
  data() {
    return {
      departments: [],
      isLoading: true,
      departmentId: null,
      settings: {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        centerMode: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      series: [60],
      chartOptions: {
        chart: {
          type: "radialBar",
          offsetY: -20,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#e7e7e7",
              strokeWidth: "100%",

              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: "#999",
                opacity: 1,
                blur: 2,
              },
            },
            dataLabels: {
              name: {
                show: false,
                color: "#F5B046",
              },
              value: {
                offsetY: -2,
                fontSize: "12px",
                color: "#fff",
              },
            },
          },
        },
        grid: {
          padding: {
            top: -10,
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#29C770"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        labels: ["Average Results"],
      },
    };
    // apex end
  },
  mounted() {
    const params = this.$route.params;
    if (params.id) {
      this.departmentId = Number(params.id);
      this.$router.push({ name: "productivity:id", params: { id: params.id } ,query: this.$route.query });
    }
    else this.$router.push({ name: "productivity" ,query: this.$route.query});

    this.getDepartmentsDetail();
  },
  methods: {
    async getDepartmentsDetail() {
      this.isLoading = true;
      await Api("GET", "Department").then((res) => {
        console.log('deparment res',res.data.data.items);

        this.departments = res.data.data.items;
        this.departments = this.departments.map((item) => {
          return {
            text: item.name,
            value: item.departmentId,
            isActive: false,
            productivity:item.departmentDayBasedProductivity.efficientLast365Day.efficientPercentage

          };
        });
        this.departments.unshift({
          text: "Tüm Departmanlar",
          value: null,
          isActive: true,
          productivity:100
        });
        const index = this.departments.findIndex(d => d.value === this.departmentId);
        if(index !== -1) this.departments[index].isActive = true;
        else this.departments[0].isActive = true;
        console.log(this.departments);
        this.isLoading = false;
      });
    },
    toggleActive(clickedCard) {
      this.departments.forEach((card) => {
        card.isActive = card === clickedCard;
      });
      this.departmentId = clickedCard.value;
      if (clickedCard.value == null) {
        this.$router.push({ name: "productivity" ,query: this.$route.query});
      } else this.$router.push({ name: "productivity:id", params: { id: this.departmentId },query: this.$route.query });
    },
    onInitCarousel() {
      console.log("our carousel is ready");
    },
    showNext() {
      this.$refs.carousel.next();
    },
  },
};
</script>

<style lang="scss">
.earnings-card {
  width: 90% !important;
  :hover,
  :focus,
  :active {
    background: #625bc9;
    cursor: pointer;
    border-radius: 7px;
  }
}

.card {
  /* Your card styles here */

  &.active {
    /* Styles for the active card */
    background: #625bc9;
    cursor: pointer;
    border-radius: 7px;
    /* Add any other styles you want for the active card */
  }
}
.card-title {
  font-size: small !important;
}
.card-body {
  padding: 1rem !important;
}
.departmentCard{
  padding: 0px;
    margin-top: 13px;
}
</style>
